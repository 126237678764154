.container{
  padding-top: 80px;
}
.navbar > .container, .container > .container{
  padding-top: 0;
  
}
::-moz-selection {
  background: #ccc;
  color: #000;
  text-shadow: none;
}

::selection {
  background: #ccc;
  color: #000;
  text-shadow: none;
}
li > a > a {
  color: #9d9d9d;
}
li > a > a:hover {
  color: #fff;
  background-color: transparent;
  text-decoration: none;
}
#Skills {
  padding-top: 70px;
  margin-top: -50px;
}
.skills {
  padding-bottom: 10px;
  margin-top: 20px;
}
.skills-bottom {
  margin-bottom: 20px;
}

i {
  color: #fff;
}

p {
  line-height: 35px;
}
svg {
  height: 100px;
  width: 75px;
}

@media only screen and (max-width: 600px) {
  svg {
    height: 100px;
    width: 33%;
  }
}
.svg {
  margin: 1px;
}

#Mirza {
  padding-bottom: 25px;
  border-radius: 50%;
}
input {
  width: 50%;
}
.centered {
  text-align: center;
}

.heading {
  font-size: 2em;
  text-shadow: 2px 2px lightgrey;
  padding-top: 20px;
  padding-bottom: 20px;
}
#Resume {
  padding-top: 50px;
  margin-top: -50px;
}
.minor-heading {
  font-size: 1em;
  color: #5b5a5a !important;
  margin-top: 20px;
  margin-bottom: 30px;
  text-shadow: 1px 1px lightgrey;
}
.email {
  color: #000;
}

.email:hover {
  transform: scale(1.6);
}

.spacer {
  margin-top: 1px;
  display: inline-block;
}

.longQuote {
  margin-bottom: 300px;
}

body {
  font-family: "Hind", sans-serif;
  font-weight: 400;
  font-size: 2em;

  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
}

h1,
h2,
h4,
h5,
h6 {
  font-weight: 700;
  color: #000;
}

#copyright {
  font-size: 0.6em;
}
i {
  padding-left: 5px;
  padding-right: 5px;
}
.navbar-brand {
  font-weight: 700;
}

.fa-at {
  color: #000;
}
.Form {
  width: 30%;
  margin: 0 auto;
}

.navbar-default {
  border-color: transparent;
}

/* ==========================================================================
      Wrap Sections
      ========================================================================== */

#headerwrap {
  background: url(../src/Images/back.jpg) no-repeat center top;
  background-color: lightskyblue;
  margin-top: 5px;
  padding-top: 10em;
  text-align: center;
  background-size: cover;
  height: 100vh;
  width: 100%;
  -webkit-background-size: 100%;
  -moz-background-size: 100%;
  -o-background-size: 100%;
  background-size: 100%;

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  min-height: 100%; /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh; /* These two lines are counted as one :-)       */

  display: flex;
  align-items: center;
}
#headerwrap > .container{
  padding-top: 0;
}
#headerwrap h1 {
  color: #fff;
  font-weight: bold;
  font-size: 4em;
  text-shadow: 1px 1px #5b5a5a;
}

#headerwrap h4,
h5 {
  font-weight: 700;
  padding-top: 5px;
  font-size: 1em;
}

.social,  
i {
  display: inline-flex;
  margin-bottom: 15px;
  color: #000;
}

#footerwrap {
  padding-top: 10px;
  padding-bottom: 5px;
  background-color: #2f2f2f;
  margin-top: auto;
}

#footerwrap p {
  color: #f2f2f2;
  margin-left: 10px;
}
#Clients {
  padding-top: 100px;
  margin-top: -100px;
  padding-bottom: 10px;
}
.carousel-item {
  margin-bottom: 55px;
}
#workwrap {
  padding-top: 100px;
  margin-top: -100px;
  padding-bottom: 10px;
  text-align: center;
  background-attachment: relative;
  background-position: center center;
  min-height: 650px;
  width: 100%;

  -webkit-background-size: 100%;
  -moz-background-size: 100%;
  -o-background-size: 100%;
  background-size: 100%;

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

#workwrap h1,
h2 {
  padding-bottom: 5px;
  color: #000;
  letter-spacing: 4px;
  font-size: 80px;
  font-weight: bold;
}

#workwrap h4 {
  font-weight: 400;
  color: #9d9d9d;
}
.GreyHeading {
  color: #efefef;
}
.DarkGreyHeading {
  color: #5b5a5a;
}
.LightGreyBackground {
  background-color: #efefef;
}

#aboutwrap {
  padding-top: 50px;
  margin-top: -50px;
  text-align: center;
  background-attachment: relative;
  background-position: center center;
  min-height: 550px;
  width: 100%;

  -webkit-background-size: 100%;
  -moz-background-size: 100%;
  -o-background-size: 100%;
  background-size: 100%;

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.project {
  height: 150px !important;
  width: 100%;
}
.ClientImage {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  vertical-align: middle;
}
.ClientInfo {
  color: #827e7e;
}

.ClientInfo:hover {
  text-decoration: none;
  color: #5b5a5a;
}

.slider-control-bottomcenter {
  display: none;
}

.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.center{
  text-align: center !important;
}

.timeline {
  list-style: none;
  padding: 20px 0 20px;
  position: relative;
}
.timeline:before {
  top: 0;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 3px;
  background-color: #eeeeee;
  right: 25px;
  margin-left: -1.5px;
}
.timeline > li {
  margin-bottom: 20px;
  position: relative;
}
.timeline > li:before,
.timeline > li:after {
  content: " ";
  display: table;
}
.timeline > li:after {
  clear: both;
}
.timeline > li:before,
.timeline > li:after {
  content: " ";
  display: table;
}
.timeline > li:after {
  clear: both;
}
.timeline > li > .timeline-panel {
  width: calc(100% - 75px);
  float: left;
  border: 1px solid #d4d4d4;
  border-radius: 2px;
  padding: 20px;
  position: relative;
  -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
}
.timeline > li > .timeline-panel:before {
  position: absolute;
  top: 26px;
  right: -15px;
  display: inline-block;
  border-top: 15px solid transparent;
  border-left: 15px solid #ccc;
  border-right: 0 solid #ccc;
  border-bottom: 15px solid transparent;
  content: " ";
}
.timeline > li > .timeline-panel:after {
  position: absolute;
  top: 27px;
  right: -14px;
  display: inline-block;
  border-top: 14px solid transparent;
  border-left: 14px solid #fff;
  border-right: 0 solid #fff;
  border-bottom: 14px solid transparent;
  content: " ";
}
.timeline > li > .timeline-badge {
  color: #fff;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 1.4em;
  text-align: center;
  position: absolute;
  top: 16px;
  right: 0px;
  margin-left: -25px;
  background-color: #ccc;
  z-index: 100;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
}
.timeline > li.timeline-inverted > .timeline-panel {
  float: right;
}
.timeline > li.timeline-inverted > .timeline-panel:before {
  border-left-width: 0;
  border-right-width: 15px;
  left: -15px;
  right: auto;
}
.timeline > li.timeline-inverted > .timeline-panel:after {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
  right: auto;
}

.timeline-badge.primary {
  background-color: #2e6da4 !important;
}
.timeline-badge.success {
  background-color: #3f903f !important;
}
.timeline-badge.warning {
  background-color: #f0ad4e !important;
}
.timeline-badge.danger {
  background-color: #d9534f !important;
}
.timeline-badge.info {
  background-color: #5bc0de !important;
}
.timeline-title {
  margin-top: 0;
  color: inherit;
}
.timeline-body > p,
.timeline-body > ul {
  margin-bottom: 0;
}
.timeline-body > p + p {
  margin-top: 5px;
}

.navbar-fixed-top {
  min-height: 80px;
}

.navbar-nav {
  display: flex;
  gap: 30px;
}
@media (max-width: 767px){
  .navbar-nav  {
    flex-direction: column;
    gap: 5px; 
  }
  .navbar-nav> a {
    padding: 0 15px;
  }

}

.navbar-nav > a {
  color:#9d9d9d;
  padding-top: 0px;
  padding-bottom: 0px;
  line-height: 80px;
  text-decoration: none;
}
.navbar-nav > a:hover {
  color: #fff;
}

@media (max-width: 767px) {
  .navbar-nav > a {
    line-height: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
@media (max-width: 680px) {
  .navbar-brand,
  img {
    margin-bottom: 25px;
  }
}
.slider-control-centerleft,
.slider-control-centerright {
  display: none;
}
.social-icons {
  padding: 0px 5px 0px;
}

.resume-icons {
  color: #fff;
}

@media only screen and (max-width: 600px) {
  .header-intro-mobile {
    padding-bottom: 40%;
  }
}
.quotations {
  color: #000;
  font-weight: thin;
  text-shadow: 1px 1px #9d9d9d;
  font-size: 30px;
  margin-left: 5px;
  margin-right: 5px;
}
